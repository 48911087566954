@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.slides {
  position: relative;
  :global(.slick-prev) {
    left: -40px;
    &::before {
      background-image: url('/img/icons/general/arrow-chevron-left-white.svg');
      @include max-screen(767px) {
        left: -30px;
        background-image: url('/img/icons/general/arrow-chevron-left.svg');
      }
    }
  }
  :global(.slick-next) {
    right: -30px;
    &::before {
      background-image: url('/img/icons/general/arrow-chevron-left-white.svg');
      @include max-screen(767px) {
        background-image: url('/img/icons/general/arrow-chevron-left.svg');
      }
    }
  }
}

.thumbnailContainer {
  width: 55px;
  height: 55px;
  position: relative;
  border-radius: 100px;
  cursor: pointer;
  @include max-screen(992px) {
    display: block;
    width: 48px;
    height: 48px;
  }
}

.numberedThumb {
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  @include h3-font-book;
  @include max-screen(992px) {
    display: flex;
    width: 48px;
    height: 48px;
  }
}

.thumbnail {
  width: 100%;
  height: 100%;
  border-radius: 100px;
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center;
}

.carouselThumbnailContainer {
  margin-right: 10px;
}

.imageOverlay {
  background-color: $pale-gold;
  opacity: 0.5;
  z-index: 1;
}
