@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.container {
  background: $paper;
  color: $text-color-primary;
  max-width: 1220px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
}

.card {
  min-width: 50%;
  position: relative;

  .imageWrapper {
    height: 100%;
    overflow: hidden;
  }

  .background {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .text {
    position: absolute;
    top: 0;
    left: 0;
    padding: 90px 30px 0;

    h5 {
      @include subheader-font-demi;
      font-weight: normal;
      margin-bottom: 22px;
    }

    p {
      @include body-font;
      max-width: 400px;
    }
  }
}

.discounts {
  flex: 1;
  padding: 0px 30px;
  margin: auto 0;
}

.discountItem {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  border-bottom: 1px solid $ice;
  padding: 30px 10px;

  &:last-child {
    border-bottom: none;
  }
}

.discountConditions {
  min-width: 120px;
  padding-right: 30px;
}

.discountPercentage {
  @include h3-font-demi;
  font-weight: normal;
  white-space: nowrap;
  padding-right: 12px;

  @include max-screen(767px) {
    @include subheader-font-demi;
  }
}

.discountQuantity {
  @include body-font-2;
  white-space: nowrap;
}

.discountIcons {
  img {
    height: 70px;
    width: auto;
    margin: 10px 2px;
  }
}

@include max-screen(1024px) {
  .container {
    text-align: center;
  }

  .wrapper {
    display: inline-block;
    text-align: left;
  }

  .card {
    width: 100%;

    .background {
      display: none;
    }

    .text {
      position: static;
      padding-top: 40px;

      p {
        @include body-font-2;
        max-width: none;
      }
    }
  }

  .discounts {
    padding: 0 30px;
  }
}

@include max-screen(500px) {
  .discounts {
    padding: 0px;
  }

  .discountItem {
    padding: 30px 20px;
    flex-wrap: wrap;
  }

  .discountConditions {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
  }

  .discountIcons {
    width: 100%;
    margin-top: 20px;

    img {
      height: 58px;
      margin: 0 2px;
    }
  }

  .card {
    .text {
      padding: 40px 20px 0;
    }
  }
}
