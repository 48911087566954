@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.container {
  width: 276px;
  overflow: hidden;

  &.small {
    max-width: 180px;
  }

  @include max-screen(767px) {
    max-width: none;
    width: 100vw;
  }
}

.content {
  .closeIcon {
    display: none;
  }

  @include max-screen(767px) {
    left: 0;
    margin: auto;
    max-width: 276px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    .material {
      background: $mx-color-white;
      box-shadow: 0 0 0 1px $grey-sky;
    }

    .closeIcon {
      display: inline-block;
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 1;

      svg {
        height: 30px;
        width: 30px;

        path {
          stroke: $mx-color-white;
          stroke-width: 3;
        }
      }
    }
  }
}

.imgContainer {
  position: relative;

  img {
    height: 200px;
    width: 100%;
    user-select: none;
  }

  [class^='Loader__dots'] {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }
}

.buttonContainer {
  display: none;

  @include max-screen(767px) {
    display: block;
    text-align: center;
    margin-top: 20px;
    width: 100%;

    .button {
      font-size: rem(11);
      letter-spacing: rem(2.2);
      padding: 8px 13px;
    }
  }
}

.textContainer {
  padding: 0 10px 5px;
  user-select: none;
}

.name {
  @include mx-font-body-2;
  border-bottom: 1px solid $paper;
  margin: 10px 0;
  padding-bottom: 10px;
  font-weight: 600;
}

.description {
  @include body-font-4;
  font-size: rem(11);

  .descriptionTitle {
    font-weight: 600;
    margin-bottom: 10px;
  }

  .descriptionText {
    margin-bottom: 15px;
  }

  .materialProperties {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 5px;
  }
}

.small {
  .imgContainer {
    width: 180px;
    height: 180px;
  }

  .textContainer {
    padding: 6px 10px 10px;
  }

  .name {
    padding-bottom: 0;
    margin-bottom: 12px;
  }

  .description {
    font-size: rem(10);
    line-height: 1.8;
    letter-spacing: normal;
  }
}
