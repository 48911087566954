@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.container {
  @include body-font-2;
  display: flex;
  align-items: center;
  gap: 10px;
}

.iconContainer {
  width: 40px;
  height: 40px;
  background-color: $seashell;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
