@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.container {
  max-width: 1220px;
  margin: 0 auto;
}

.header {
  @include h3-font-demi;
  margin-top: 30px;
  margin-bottom: 40px;
  text-align: center;
  @include max-screen(992px) {
    margin-top: 20px;
    margin-bottom: 30px;
    letter-spacing: 0;
  }
  @include max-screen(767px) {
    margin-top: 15px;
  }
}

.videoContainer {
  margin-bottom: 30px;
  @include max-screen(767px) {
    margin-bottom: 15px;
  }
  .video {
    :global(.Video__videoWrapper) {
      width: 100%;
      // 16:9
      padding-top: 56.25%;
      height: 0px;
      position: relative;
      @include max-screen(767px) {
        // 4:3
        padding-top: 75%;
      }
    }
    :global(.Video__video) {
      object-fit: cover;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    :global(.Video__playIcon) {
      left: 0%;
    }
    :global(.Video__content) {
      @include max-screen(767px) {
        padding: 0px;
      }
    }
  }
}

.allFeaturesContainer {
  @include fadeIn(600ms);
  margin-bottom: 15px;
}

.tilesContainer,
.videoContainerMaintainRatio {
  @include max-screen(1280px) {
    margin: 0 30px;
  }
  @include max-screen(767px) {
    margin: 0 10px;
  }
}

.featuresContainer {
  display: flex;
  flex-wrap: wrap;
}

.tileContainer {
  margin-right: 30px;
  margin-bottom: 30px;
  width: calc(50% - 15px);

  &:nth-of-type(2n + 2) {
    margin-right: 0px;
  }

  @include max-screen(767px) {
    margin-right: 0px;
    margin-bottom: 20px;
    width: 100%;
  }
}

.additionalTileContainer {
  margin-right: 30px;
  margin-bottom: 40px;
  width: calc((100% / 3) - 20px);

  &:nth-child(3n + 3) {
    margin-right: 0px;
  }

  @include max-screen(992px) {
    margin-bottom: 20px;
  }

  @include max-screen(767px) {
    width: calc((100% / 2) - 5px);
    margin-right: 0;
    &:nth-child(odd) {
      margin-right: 10px;
    }
  }
}

.additionalOptionsTitle {
  @include h3-font-demi;
  text-align: center;
  margin-bottom: 20px;
}

.modalContent {
  padding: 0;
}

.modalOverlay {
  :global(.Modal__panel) {
    max-width: 803px;
    min-height: fit-content;
  }
}

.modalHeader {
  display: none;
}
