@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.container {
  position: relative;
  line-height: 0;
  height: inherit;

  &:active,
  &:not(.dot).highlighted {
    // do not color the background when the 'noHighlight' prop is true.
    &:not(.noHighlight) {
      background-color: $sand;
    }
  }

  &:not(.small) {
    .zigzagWrapper {
      @include zigzag(white, 4);
    }

    &:not(.dot).highlighted {
      &:not(.noHighlight) {
        .zigzagWrapper {
          @include zigzag($sand, 4);
        }
      }
    }
  }

  &.small {
    .zigzagWrapper {
      @include zigzag(white, 3);
    }

    &:not(.dot).highlighted {
      &:not(.noHighlight) {
        .zigzagWrapper {
          @include zigzag($sand, 3);
        }
      }
    }
  }

  &.tiny {
    .zigzagWrapper {
      @include zigzag(white, 2);
    }

    &:not(.dot).highlighted {
      &:not(.noHighlight) {
        .zigzagWrapper {
          @include zigzag($sand, 2);
        }
      }
    }
  }
}

.dot {
  .image:after {
    content: '';
    position: absolute;
    user-select: none;
    pointer-events: none;
    z-index: 1;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border-radius: 50%;
    height: 15px;
    width: 15px;
  }

  .image:hover:after {
    @include fadeIn(0.2s);
    background-color: $white;
    border: 2px solid $neutral;
  }

  &.highlighted {
    .image:after {
      background-color: $mx-color-primary;
      border: 2px solid $ice;
      animation: none;
    }
  }
}

.image {
  display: inline-block;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  position: relative;

  img {
    max-width: 100%;
  }
}

.title {
  color: $charcoal;
  margin: 0.4em 0 0.2em;
  @include mx-font-body;
  line-height: 1.31;
  // even out plus icons under the title
  min-height: 2.62em;
  // align single line title to middle
  display: flex;
  flex-direction: column;
  justify-content: center;
  hyphens: auto;

  @include max-screen(768px) {
    font-size: rem(14);
    line-height: 1.21;
    min-height: 2.42em;
    margin-bottom: 0.4em;
  }

  @include max-screen(767px) {
    font-size: rem(12);
    user-select: none;
  }
}

.addButton {
  position: relative;
  cursor: pointer;
  color: $grey-sky;
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  border: 1px solid $grey-sky;

  &:before {
    content: '';
    height: 200%;
    left: -50%;
    position: absolute;
    top: -50%;
    width: 200%;
  }

  svg {
    display: block;
    height: 23px;
    width: 23px;
  }

  path {
    stroke: $ash;
  }

  &:hover {
    border-color: $mx-color-black;

    path {
      stroke: $mx-color-black;
    }
  }

  @include max-screen(992px) {
    height: 20px;
    line-height: 20px;
    width: 20px;

    svg {
      height: 18px;
      width: 18px;
    }
  }

  @include max-screen(767px) {
    height: 17px;
    line-height: 17px;
    width: 17px;

    svg {
      height: 15px;
      width: 15px;
    }
  }
}

.added {
  display: block;

  .addButton:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    background: $ash;
    border: 2px solid $mx-color-white;
    @include fadeIn(100ms);
  }
}

.invalid {
  &:after,
  &:before {
    content: '';
    user-select: none;
    pointer-events: none;
    display: block;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    position: absolute;
    z-index: 1;
  }
}

.invalidImage {
  user-select: none;
  pointer-events: none;

  &:after,
  &:before {
    content: '';
    user-select: none;
    pointer-events: none;
    display: block;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    position: absolute;
    z-index: 1;
  }

  &:after {
    background: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0) 49%,
      rgba(255, 255, 255, 1) 49%,
      rgba(255, 255, 255, 1) 51%,
      rgba(255, 255, 255, 0) 51%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  &:before {
    background: linear-gradient(
      135deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0) 49%,
      rgba(255, 255, 255, 1) 49%,
      rgba(255, 255, 255, 1) 51%,
      rgba(255, 255, 255, 0) 51%,
      rgba(255, 255, 255, 0) 100%
    );
  }
}

.small {
  .title {
    font-size: rem(11);
  }

  .addButton {
    height: 20px;
    line-height: 20px;
    width: 20px;

    svg {
      height: 20px;
      width: 20px;
      margin: -1px 0 0 -1px;
    }
  }
}

.button {
  display: flex;
}

.configuratorLayout {
  .title {
    @include min-screen(993px) {
      display: none;
    }
  }
}
