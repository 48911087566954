@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.popover {
  :global(.Popover-body) {
    background: rgba(0, 0, 0, 0.6);
  }

  path {
    @include min-screen(768px) {
      stroke: $mx-color-white;
      stroke-width: 4;
    }
  }
}

.cancelMenu {
  // should cancel context menu on longpress
  // only works in safari
  -webkit-touch-callout: none;
  user-select: none;
}
