@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.container {
  max-width: 803px;
  margin: 0 auto;
  @include fadeIn(600ms);

  .mediaContainer {
    // 4:3
    padding-top: 75%;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 0px;
    position: relative;
    .video {
      object-fit: cover;
      width: 100%;
      height: auto;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    .thumbnailsContainer.withCarousel {
      @include max-screen(992px) {
        display: none;
      }
    }

    .thumbnailsContainer {
      position: absolute;
      top: calc(100% - 80px);
      align-self: center;
      margin: 15px 0 0 -15px;
      display: flex;
      margin-bottom: 30px;
      column-gap: 10px;
      @include max-screen(767px) {
        display: none;
      }
    }
  }

  .contentContainer {
    padding-bottom: 30px;
    background-color: $light-pink;
    @include max-screen(767px) {
      padding: 20px;
    }
    .carouselContainer {
      display: none;
      @include max-screen(767px) {
        display: block;
        margin: 0 auto;
        width: 230px;
        margin-bottom: 20px;
      }
    }
    .title {
      @include subheader-font-demi;
      margin: 0;
      padding: 20px;
      @include max-screen(767px) {
        padding: 0;
      }
    }
    .description {
      @include body-font;
      margin: 0px 20px;
      @include max-screen(767px) {
        margin: 20px 0;
      }
    }
    .buttonContainer {
      display: flex;
      justify-content: center;
      margin: 30px 0 0 0;
      column-gap: 10px;
      @include max-screen(992px) {
        flex-direction: column;
        row-gap: 20px;
        margin: 20px 20px 0;
      }

      @include max-screen(767px) {
        margin: 0;
      }

      .button {
        background-color: $light-pink;
        border-radius: 2px;
        border: solid 1px $charcoal;
        color: $charcoal;
        text-align: center;
        &:hover {
          opacity: 0.5;
        }
      }
    }
  }

  .contentContainer.noPaddingBottom {
    padding-bottom: 0px;
  }
}

.grow {
  flex-grow: 1;
}

.carouselContainer {
  width: 290px;
  margin: 10px 0;
  align-self: center;
  display: none;
  @include max-screen(992px) {
    display: block;
    position: absolute;
    top: calc(100% - 80px);
  }
  @include max-screen(767px) {
    position: static;
    display: none;
  }
}

.formContainer {
  background-color: #fff;
}

.closeIconContainer {
  z-index: 1;
  float: right;
  position: relative;
  left: -40px;
}

.close {
  position: absolute;
  align-self: flex-end;
  height: 40px;
  width: 40px;
  svg {
    * {
      stroke-width: 4;
      stroke: $white;
    }
  }
  &:hover {
    opacity: 0.5;
  }
}

.close.blackColor {
  svg {
    * {
      stroke: black;
    }
  }
}

.thumbnailsContainer.videoOverlay {
  position: relative;
  bottom: 310px;
}

.mobileThumbsnailContainer {
  display: none;
  @include max-screen(767px) {
    display: flex;
    justify-content: center;
    display: flex;
    column-gap: 10px;
    margin-bottom: 10px;
  }
}
