@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

$additionalTileButtonSize: 35px;
$additionalTileButtonMobileSize: 23px;

.videoContainer {
  pointer-events: none;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  z-index: -1;
  &.show {
    z-index: 1;
  }
}

.videoTileContainer {
  cursor: pointer;
  background-color: $light-pink;
}

.video {
  display: block;
  height: 100%;
  align-self: center;
}

.tile {
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0px 20px;

  @include min-screen(768px) {
    height: 475px;
  }

  @include max-screen(768px) {
    height: 424px;
    background-size: 100% auto;
  }

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .buttonContainer {
    @include min-screen(1024px) {
      @include transition(opacity 200ms ease-in);
      opacity: 0;
    }
  }
  &:hover {
    .buttonContainer {
      opacity: 1;
    }
  }
  @include max-screen(1024px) {
    padding: 0;
    background-position: center;
    background-origin: content-box;
    padding-bottom: 80px;

    .buttonContainer {
      width: 100%;
      display: flex;
    }
  }
}

.fillerTile {
  cursor: auto;
  display: flex;
  justify-content: center;
  padding: 0 74px;
  background-color: $light-pink;
  @include max-screen(1280px) {
    padding: 0 37px;
  }
  @include max-screen(1024px) {
    padding: 0 20px;
    padding-top: 75%;
    height: 100%;
    position: relative;
  }

  .contentContainer {
    @include max-screen(1024px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 20px;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .description {
    width: 100%;
    margin: 0 0 30px;
    color: $charcoal;
    @include h1-font-demi;
    @include max-screen(1024px) {
      @include h3-font-demi;
    }
    @include max-screen(767px) {
      margin: 0;
      padding-bottom: 20px;
    }
  }

  .link {
    white-space: nowrap;
    width: fit-content;
    border-bottom: solid 1px $charcoal;
    a {
      text-transform: uppercase;
      color: $charcoal;
      @include button-cta-font;
      &:hover {
        color: $ash;
      }
    }
  }
}

.grow {
  flex-grow: 1;
}

.titleContainer {
  min-height: 65px;
  margin-bottom: 19px;
  border-radius: 2px;
  background-color: $light-pink;
  display: flex;
  align-items: center;
  padding: 0 10px;
  z-index: 10;
  @include max-screen(1024px) {
    min-height: 80px;
    align-items: flex-start;
    height: auto;
    padding: 20px 10px;
    justify-content: center;
    flex-direction: column;
    row-gap: 10px;
    margin: 80px 0 0;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  @include max-screen(767px) {
    margin: 80px 0 0 0;
  }
  p {
    @include subheader-font-demi;
    margin: 0;
    width: 100%;
  }
}

.additionalTile {
  border-radius: 5px;
  @include max-screen(1024px) {
    max-height: 350px;
    padding-bottom: 90px;
    background-origin: content-box;
  }
  .titleContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    p {
      width: calc(100% - ((#{$additionalTileButtonSize}) + 5px));
      display: flex;
      height: 100%;
      align-items: center;
    }
  }

  &:hover {
    .additionalTileButton {
      opacity: 1;
    }
  }

  @include max-screen(1024px) {
    .titleContainer {
      p {
        hyphens: auto;
        @include max-screen(1024px) {
          @include body-font-3;
          min-height: 50px;
          align-items: flex-start;
        }
      }
      position: absolute;
      bottom: 0;
      width: 100%;
      min-height: 90px;
      padding: 20px 10px;
    }
  }
  @include max-screen(767px) {
    .titleContainer {
      p {
        width: calc(100% - ((#{$additionalTileButtonMobileSize}) + 10px));
        @include body-font-3;
      }
    }
  }

  @include max-screen(767px) {
    height: 290px;
    background-size: cover;
    background-position: 50% 0;
  }

  .additionalTileButton {
    opacity: 1;
    &:global(.Button__button) {
      height: $additionalTileButtonSize;
      width: $additionalTileButtonSize;
      border-radius: 18px;
      padding: 0;
      border: solid 1px $charcoal;
    }

    &:hover,
    &.active {
      background-color: $charcoal;
      .additionalTileButtonIcon {
        svg {
          * {
            stroke: $white;
          }
        }
      }
    }

    .additionalTileButtonIcon {
      display: flex;
      justify-content: center;
      svg {
        height: 24px;
        transform: rotate(0.74turn);
        * {
          stroke-width: 6;
          stroke: $charcoal;
        }
      }
    }
    @include max-screen(767px) {
      &:global(.Button__button) {
        height: $additionalTileButtonMobileSize;
        width: $additionalTileButtonMobileSize;
      }
      .additionalTileButtonIcon {
        svg {
          height: 15px;
          * {
            stroke-width: 8;
          }
        }
      }
    }
  }
}
